<template>
  <v-container fluid>
    <section-header
      v-if="viewMode === 'table'"
      :title="$t('settingsPage.organizations')"
      :action-text="$t('organization.createOrganization')"
      @create="onCreateOrganization"
    />

    <v-card
      class="pt-2 pb-6 px-6 mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <template v-if="viewMode === 'table'">
        <OrganizationTable
          v-if="hasOrganizations"
          :headers="headers"
          :items="orgs"
          item-key="uid"
          @edit="onEditOrganization"
          @select-row="onSelectOrg"
        />

        <EmptyOrganization
          v-else
          @create="onCreateOrganization"
        />
      </template>

      <CreateUpdateOrganization
        v-else
        :data="selectedOrg"
        @create="createOrganization"
        @update="updateOrganization"
        @delete="deleteOrganization"
        @go-back="viewMode = 'table'"
      />
    </v-card>
  </v-container>
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
import * as _ from 'lodash'

import SectionHeader from '@/components/Form/SectionHeader.vue';
import OrganizationTable from '@/components/Settings/Organization/OrganizationTable.vue';
import CreateUpdateOrganization from '@/components/Settings/Organization/CreateUpdateOrganization.vue';
import EmptyOrganization from '@/components/Settings/Organization/EmptyOrganization.vue';

import makeOrgService from '@/services/api/org';
import makeUserService from "@/services/api/user";
import { showSuccessToast, showErrorToast } from '@/utils/toast';

const { mapState, mapMutations } = createNamespacedHelpers('user');

export default {
  name: 'Organizations',

  components: {
    SectionHeader,
    OrganizationTable,
    CreateUpdateOrganization,
    EmptyOrganization,
  },

  data () {
    return {
      headers: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: true,
          value: 'name',
          class: 'elevation-0 rounded-l-lg',
        },
        {
          text: '',
          value: 'uid',
          sortable: false,
          class: 'rounded-r-lg',
          width: 130,
        },
      ],
      viewMode: 'table',
      selectedOrg: null,
      orgService: null,
      userService: null,
      isLoading: false,
    }
  },

  computed: {
    ...mapState(['orgs', 'user', 'currentAccount' ]),

    hasOrganizations() {
      return this.orgs.length > 0
    }
  },

  created() {
    this.orgService = makeOrgService(this.$api)
    this.userService = makeUserService(this.$api)
  },

  mounted() {
    this.getOrganization()
  },

  methods: {
    ...mapActions({
      setOrgs: 'user/setOrgs',
      uploadToServer: 'attachment/uploadToServer'
    }),
    ...mapMutations(['updateOrg']),

    onCreateOrganization() {
      this.selectedOrg = {
        uid: '',
        name: '',
        handle: '',
      }

      this.viewMode = 'create'
    },

    onSelectOrg(item) {
      this.$router.push({
        name: 'UserOrganizations',
        params: {
          handle: item.handle,
        },
      });
    },

    async getOrganization() {
      try {
        const response = await this.userService.getOrgs(this.user.uid)
        this.setOrgs(response.data?.orgs || [])
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.error || err.response?.data[0]?.msg)
      }
    },

    async createOrganization(item, file) {
      this.isLoading = true
      try {
        const data = {
          name: item.name,
          handle: item.handle,
        }


        const response = await this.orgService.newOrg(data)
        
        const org = response.data;
        
        this.viewMode = 'table'

        showSuccessToast(this.$swal, 'New organization has been created')
        
        this.setOrgs([{...org, type:"org"}, ...this.orgs]);

        if(file){
          const mediaType = 'profile-picture'
          const handle = this.$store.getters['user/currentAccount'].handle;
          const objectUrl = await this.uploadToServer({handle: handle, mediaType,file: file});
          
          if(objectUrl)
            org.avatarUrl = objectUrl

          this.updateOrg(org) 
        }


      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || err.response?.data?.error || err.response.data[0]?.msg)
      } finally {
        this.isLoading = false
      }
    },

    async onEditOrganization(item) {
      this.selectedOrg = _.cloneDeep(item)

      this.viewMode = 'edit'
    },

    async updateOrganization(item) {
      this.isLoading = true
      try {
        const data = {
          orgName: item.name,
          handle: item.handle,
        }

        if (item.avatarUrl) {
          data.avatarURL = item.avatarUrl
        }

        await this.orgService.updateOrg(data)

        const updatedOrgs = this.orgs.map((org) => {

          if (org.uid === item.uid) {

            return {
              ...org,
              name: item.name,
              avatarUrl: item.avatarUrl,
            };
          }
          return org;
        });
        this.setOrgs(updatedOrgs)

        showSuccessToast(this.$swal, this.$t("success.orgnizationUpdated"))

        this.viewMode = 'table'
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.error || err.response.data[0]?.msg)
      } finally {
        this.isLoading = false
      }
    },

    async deleteOrganization(password) {
      this.isLoading = true
      try {
        await this.orgService.deleteOrg(this.selectedOrg.handle, {
          password,
        })
        const filterdOrgs = this.orgs.filter(item => item.uid !== this.selectedOrg.uid)

        this.setOrgs(filterdOrgs || [])

        showSuccessToast(this.$swal, this.$t("success.orgnizationDeleted"))

        this.viewMode = 'table'
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.error || err.response.data[0]?.msg)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
